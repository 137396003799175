import React, { lazy, Suspense } from 'react';

const WebStrankyTitle = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'WebStrankyTitleLazy' */ './WebStrankyTitle'));

function WebStrankyTitleLazy(props) {
    return (
        <div>
            <Suspense fallback={<div>Loading WebStrankyTitle ...</div>}>
                <WebStrankyTitle {...props} />
            </Suspense>
        </div>
    );
}

export default WebStrankyTitleLazy;